import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class GroupService {
  displayedColumns: string[] = ['nombre', 'descripcion', 'estatus', 'opciones'];
  constructor(private _http: HttpClient) { }
  
  async getAllGroups(){
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/groups")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
  async createGroup(group){
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/groups" , {group})
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
  async getDivisions(){
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/stores/divisions")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
  async getStores(){
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/stores/list")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
}
