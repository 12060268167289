import { HttpClient } from '@angular/common/http';
import { User } from './bqdata-model';
import { Injectable } from '@angular/core';
import { Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class BqAuthService implements CanActivate {
  private _isLogged: boolean;
  private _userData: User;

  constructor(private _http: HttpClient, private router: Router) {
    this._isLogged = false;
    this._userData = new User();
  }

  log(text: string, data: any = {}) {
    console.log(
      '[%cAUTH%c][%o] %c%s',
      'color: red',
      '',
      data,
      'color: gray',
      text,
    );
  }

  login(username: string, password: string) {
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.account + '/login', {
          email: username,
          password: password,
        })
        .toPromise()
        .then(async(loginRes: any) => {
          console.log(loginRes);
          let uData = loginRes.user;
          this._userData = uData;
          await this.sync(this._userData._id , loginRes.token).then(res =>{
            // console.log("group" , res);
            // this._userData.groups = res;
          })
          this._isLogged = true;
          window.sessionStorage.setItem('BQT.token', loginRes.token);
          window.sessionStorage.setItem('BQT.cudat', JSON.stringify(uData));
          resolve(uData);
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }
  async sync(user, token){
    console.log("Sync " + user + " token" );
    return new Promise( (resolve , reject ) =>{
      this._http
        .post(environment.API_URL + '/sync', {
          user: user,
          token: token,
        })
      .toPromise()
        .then( (res : any) =>{
          console.log(res);
          resolve(res.user.groups);
        })
        .catch( err =>{
          console.log(err);
        })
    })
  }
  canActivate() {
    let uToken = window.sessionStorage.getItem('BQT.token');
    let uData = window.sessionStorage.getItem('BQT.cudat');
    console.log(uToken , uData)
    if (!uToken || !uData) {
      this.log('[AUTH] user is not logged!');
      // this._userData = new User();
      this._isLogged = false;

      this.router.navigate(['/login']);
      return false;
    } else {
      this.log('[AUTH] user is logged!');
      this._isLogged = Boolean(window.sessionStorage.getItem('BQT.token'));
      this._userData = JSON.parse(window.sessionStorage.getItem('BQT.cudat'));
      this.log('Current user: ' + this._userData.username, this._userData);
      return true;
    }
  }

  getUserData() {
    return this._userData;
  }

  isLogged() {
    return this.canActivate();
  }

  isDeveloper() {
    return this._userData.isDeveloper;
  }

  isAdmin() {
    return this._userData.isAdmin;
  }
}
