import { BqAuthService } from "../services/bq-auth.service";
import { User } from "../services/bqdata-model";
import { AdminApiService } from "./../admin-api.service";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from "@angular/core";

@Component({
  selector: "app-admin-users-list",
  templateUrl: "./admin-users-list.component.html",
  styleUrls: ["./admin-users-list.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AdminUsersListComponent implements OnInit {
  public _userList: any;
  public _totalUsers: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  private _updateList: boolean;
  public _isDeveloper: Boolean = false;

  @Input()
  set updateList(updateList: boolean) {
    if (updateList === true) {
      this.loadUsers();
    }
  }
  get updateList(): boolean {
    return this._updateList;
  }

  @Output()
  onSelectedUser = new EventEmitter<string>();

  userList: any;
  constructor(
    private adminApi: AdminApiService,
    private bqAuth: BqAuthService
  ) {}

  ngOnInit() {
    this._isDeveloper = this.bqAuth.isDeveloper();
    this._userList = [];
    this._totalUsers = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this.loadUsers();
  }

  editUser(userId) {
    this.onSelectedUser.emit(userId);
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadUsers();
  }

  loadUsers() {
    this.adminApi
      .listUsers(this._currentPage, this._itemsByPage, {})
      .then(async (res: any) => {
        console.log(res.items.length);
        // this._userList = res.items;
        if (res.items.length > 0) {
          console.log("Mayor a 0");
          for (let items of res.items) {
            console.log(items);
            console.log(JSON.parse(items.metadata));
            let usr = JSON.parse(items.metadata);
            items.metadata = usr;
            this._userList.push(items);
          }
        }
        console.log(this._userList);
        this._totalUsers = res.total;
        this._totalPages = Math.round(res.total / this._itemsByPage) + 1;
        this._updateList = false;
      })
      .catch(err => {});
  }
  blockUsers(idUser) {
    console.log(idUser);
  }
  changeActivity(idUser) {
    console.log(idUser);
    this.adminApi
      .changeStatusActivityPermisson(idUser)
      .then((response: any) => {
        if (response.ok == true) {
          this.ngOnInit();
        }
      });
  }
  changeInactivity(idUser) {
    console.log(idUser);
    this.adminApi
      .changeStatusInactivityPermisson(idUser)
      .then((response: any) => {
        if (response.ok == true) {
          this.ngOnInit();
        }
      });
  }
  changeReports(idUser) {
    console.log(idUser);
    this.adminApi.changeStatusReportsPermisson(idUser).then((response: any) => {
      if (response.ok == true) {
        this.ngOnInit();
      }
    });
  }
}
