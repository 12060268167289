import { Component, OnInit } from '@angular/core';
import { AdminApiService } from "../admin-api.service";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-load-happy',
  templateUrl: './load-happy.component.html',
  styleUrls: ['./load-happy.component.scss']
})
export class LoadHappyComponent implements OnInit {
  stores;
  store;
  public currentFile: any;
  public __sending: boolean;
  constructor(private api : AdminApiService , public toastr: ToastrService,) { }

  ngOnInit() {
   this.api.getAllStores().toPromise().then(res =>{
    //  console.log(res);
     this.stores = res.items;
     console.log(this.stores);
   })
  }
  uploadCSV(ev){
    console.log(this.store);
    if(!this.store){
      this.toastr.error("Debe seleccionar una tienda.");
    }else{
      this.__sending = true;
      if (!ev || !ev.target || !ev.target.files[0]) {
        this.toastr.error("Debe seleccionar un fichero.");
        return;
      }
      console.log("Uploading CSV %c%s", "color: green", ev.target.files[0].name);
      let reader = new FileReader();
      let file = ev.target.files[0];
      console.log(file);
      reader.readAsDataURL(file);
      reader.onload = () => {
        // let tempFile = {
        //   filename: file.name,
        //   type: file.type,
        //   data: reader.result.split(",")[1]
        // };
  
        let formData: FormData = new FormData();
        formData.append("csvfile", ev.target.files[0] , file.name);
        console.log(formData);
        this.api
          .uploadSurveys(formData , this.store )
          .then(res => {
            this.toastr.success(
              "El fichero se ha subido corr ectamente, en cuanto haya sido procesado se mostrará en la pantalla de NPS."
            );
          })
          .catch(err => {
            this.toastr.error(err, "Ha habido un error al subir el archivo.");
          });
      };
    }
 
  }
}
