// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: "DEV",
  enableExperiments: true,
  enableDevelMode: true,
  // API_URL: "https://ec2-34-246-124-60.eu-west-1.compute.amazonaws.com", //Canarias
  // API_URL: 'https://ec2-54-194-10-219.eu-west-1.compute.amazonaws.com', //peninsula
  // API_URL: 'https://worten-api.360cvm.net', //peninsula prod
  // API_URL: 'https://worten-canarias-api.360cvm.net', //canarias prod
  // API_URL: 'https://api.farmacias.satisvalue.com', //C7anarias prod
  account: "https://account.360bvm.net", //Canarias prod
  API_URL: "https://api.farmashopper.360bvm.net", //Canarias prod
  // API_URL: 'https://api.satisvalue.com', //Canarias prod
  // API_URL: 'http://130.211.117.33:3241', //Canarias prod
  mysteryURL: "https://mystery.farmashopper.360cvm.net"
  // mysteryURL: "https://worten-canarias-everest.360cvm.net/"
};
