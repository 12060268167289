import { HappyPointConfigComponent } from './happy-point-config/happy-point-config.component';
import { FunnelEditorPageComponent } from './funnel-editor-page/funnel-editor-page.component';
import { MailQueuePageComponent } from './mail-queue-page/mail-queue-page.component';
import { GmbadminPageComponent } from './gmbadmin-page/gmbadmin-page.component';
import { ExportPageComponent } from './export-page/export-page.component';
import { BqDeveloperAuthService } from './services/bq-developer-auth.service';
import { DeviceManagerPageComponent } from './device-manager-page/device-manager-page.component';
import { AlertPageComponent } from './alert-page/alert-page.component';
import { NpspromotionEditPageComponent } from './npspromotion-edit-page/npspromotion-edit-page.component';
import { NpssurveysPageComponent } from './npssurveys-page/npssurveys-page.component';
import { PromotionsPageComponent } from './promotions-page/promotions-page.component';
import { BqAuthService } from './services/bq-auth.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceAssignPageComponent } from './device-assign-page/device-assign-page.component';

import { AlertTrackingComponent } from './alert-tracking/alert-tracking.component';

import { AdminPageComponent } from './admin-page/admin-page.component';
import { UserEditPageComponent } from './user-edit-page/user-edit-page.component';
import { DeveloperPageComponent } from './developer-page/developer-page.component';
import { StoreDeviceEditPageComponent } from './store-device-edit-page/store-device-edit-page.component';
import { MailEditorPageComponent } from './mail-editor-page/mail-editor-page.component';
import { SurveysPageComponent } from './surveys-page/surveys-page.component';
import { NpspageComponent } from './npspage/npspage.component';
import { CustomersPageComponent } from './customers-page/customers-page.component';
import { EverestPageComponent } from './everest-page/everest-page.component';
import { EverestDetailPageComponent } from './everest-detail-page/everest-detail-page.component';
import { ActivityUsersComponent } from './activity-users/activity-users.component';
import { GroupsComponent } from './groups/groups.component';
import { DivisionesComponent } from './divisiones/divisiones.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { LoadHappyComponent } from './load-happy/load-happy.component';
const adminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'devel',
    component: DeveloperPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'users',
    component: UserEditPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'devices',
    component: DeviceManagerPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'funnel',
    component: FunnelEditorPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'stores',
    component: StoreDeviceEditPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'everest',
    component: EverestPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'gmb',
    component: GmbadminPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'mail-queue',
    component: MailQueuePageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'assign-device',
    component: DeviceAssignPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'customers-singleton',
    component: CustomersPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'export',
    component: ExportPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'alerts',
    component: AlertPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'nps',
    component: NpspageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'nps-promotion/:promo_id',
    component: NpspromotionEditPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'everest-detail/:everest_id',
    component: EverestDetailPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'nps-surveys',
    component: NpssurveysPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'surveys',
    component: SurveysPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'mail',
    component: MailEditorPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'alert-tracking',
    component: AlertTrackingComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'promotions',
    component: PromotionsPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'hpconfig',
    component: HappyPointConfigComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'nps-search',
    component: NpssurveysPageComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'activity-users',
    component: ActivityUsersComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'add-groups',
    component: AddGroupComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'divisions',
    component: DivisionesComponent,
    canActivate: [BqAuthService],
  },
  {
    path: 'cargar-hp',
    component: LoadHappyComponent,
    canActivate: [BqAuthService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
