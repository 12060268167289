import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-surveys',
  templateUrl: './upload-surveys.component.html',
  styleUrls: ['./upload-surveys.component.scss']
})
export class UploadSurveysComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
