import { NgbDateESParserFormatter } from './services/es-date-formatter.module';
import { CommonApiService } from './services/common-api.service';
import { TestPageComponent } from './test-page/test-page.component';
import { UserApiService } from './services/user-api.service';
import { UserProfilePageComponent } from './user-profile-page/user-profile-page.component';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddHeaderInterceptor } from './services/http.module';
import { AdminRoutingModule } from './admin-routing.module';
import { BqAuthService } from './services/bq-auth.service';
import { StoreApiService } from './services/store-api.service';
import { AdminApiService } from './admin-api.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NvD3Module } from 'ng2-nvd3';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';


// jquery import
import * as $ from 'jquery';

window['$'] = $;
window['jQuery'] = $;

// d3 and nvd3 should be included somewhere
import 'd3';
import 'nvd3';

import { ChartsModule } from 'ng2-charts';

import 'froala-editor/js/froala_editor.pkgd.min.js';

import * as moment from 'moment';
import { MomentModule } from 'angular2-moment';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { CountoModule } from './libs/counto/counto.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { DeveloperPageComponent } from './developer-page/developer-page.component';
import { BaseApiService } from './services/base-api.service';
import { AdminUsersListComponent } from './admin-users-list/admin-users-list.component';
import { AdminUserEditComponent } from './admin-user-edit/admin-user-edit.component';
import { AdminStoresListComponent } from './admin-stores-list/admin-stores-list.component';
import { AdminStoreEditComponent } from './admin-store-edit/admin-store-edit.component';
import { UserEditPageComponent } from './user-edit-page/user-edit-page.component';
import { TablePaginationComponent } from './libs/table-pagination/table-pagination.component';
import { StoreDeviceEditPageComponent } from './store-device-edit-page/store-device-edit-page.component';
import { AdminDeviceListComponent } from './admin-device-list/admin-device-list.component';
import { AdminDeviceEditComponent } from './admin-device-edit/admin-device-edit.component';
import { IsDeveloperDirective } from './services/is-developer.directive';
import { MailEditorPageComponent } from './mail-editor-page/mail-editor-page.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { MailTemplateListComponent } from './mail-template-list/mail-template-list.component';
import { MailTemplateEditorComponent } from './mail-template-editor/mail-template-editor.component';
import { MailTemplateNewComponent } from './mail-template-new/mail-template-new.component';
import { AlertListComponent } from './alert-list/alert-list.component';
import { AdminStatsComponent } from './admin-stats/admin-stats.component';
import { RoundNumberPipe } from './services/round-number.pipe';
import { LoginPageComponent } from './login-page/login-page.component';
import { PlaygroundPageComponent } from './playground-page/playground-page.component';
import { CsvUploadPageComponent } from './csv-upload-page/csv-upload-page.component';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { SurveyDetailsComponent } from './survey-details/survey-details.component';
import { SurveysPageComponent } from './surveys-page/surveys-page.component';
import { ObjectDumpPipe } from './services/object-dump.pipe';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { JsonPipe } from '@angular/common';
import { PromotionsPageComponent } from './promotions-page/promotions-page.component';
import { PromotionsListComponent } from './promotions-list/promotions-list.component';
import { PromotionsEditComponent } from './promotions-edit/promotions-edit.component';
import {
  NgbDatepickerConfig,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NpspageComponent } from './npspage/npspage.component';
import { NpspromotionListComponent } from './npspromotion-list/npspromotion-list.component';
import { NpssurveyListComponent } from './npssurvey-list/npssurvey-list.component';
import { NpssurveysPageComponent } from './npssurveys-page/npssurveys-page.component';
import { NpspromotionEditComponent } from './npspromotion-edit/npspromotion-edit.component';
import { NpspromotionEditPageComponent } from './npspromotion-edit-page/npspromotion-edit-page.component';
import { NpsMailingTraceComponent } from './nps-mailing-trace/nps-mailing-trace.component';
import { AlertPageComponent } from './alert-page/alert-page.component';
import { CustomersPageComponent } from './customers-page/customers-page.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { EverestPageComponent } from './everest-page/everest-page.component';
import { EverestListComponent } from './everest-list/everest-list.component';
import { EverestGenerateComponent } from './everest-generate/everest-generate.component';
import { EverestResumeComponent } from './everest-resume/everest-resume.component';
import { DeviceManagerPageComponent } from './device-manager-page/device-manager-page.component';
import { EverestProfilesComponent } from './everest-profiles/everest-profiles.component';
import { DeviceDashboardResumeComponent } from './device-dashboard-resume/device-dashboard-resume.component';
import { HpdashboardResumeComponent } from './hpdashboard-resume/hpdashboard-resume.component';
import { NpsdashboardResumeComponent } from './npsdashboard-resume/npsdashboard-resume.component';
import { BqDeveloperAuthService } from './services/bq-developer-auth.service';
import { ExportPageComponent } from './export-page/export-page.component';
import { EverestDetailPageComponent } from './everest-detail-page/everest-detail-page.component';
import { GmbadminPageComponent } from './gmbadmin-page/gmbadmin-page.component';


import { ActivityUsersComponent } from './activity-users/activity-users.component';
import { GroupsComponent } from './groups/groups.component';
import { UploadHappyComponent } from './upload-happy/upload-happy.component';


import {
  GoogleApiModule,
  GoogleApiService,
  GoogleAuthService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig,
} from 'ng-gapi';
import { GmbuserService } from './services/gmbuser.service';
import { GmbapiService } from './services/gmbapi.service';
import { MailQueuePageComponent } from './mail-queue-page/mail-queue-page.component';
import { DeviceAssignPageComponent } from './device-assign-page/device-assign-page.component';
import { AlertTrackingComponent } from './alert-tracking/alert-tracking.component';
import { AlertTrackingGraphComponent } from './alert-tracking-graph/alert-tracking-graph.component';
import { FunnelEditorPageComponent } from './funnel-editor-page/funnel-editor-page.component';
import { HappyPointConfigComponent } from './happy-point-config/happy-point-config.component';
import { ExcelService } from './services/excel.service';
import { DivisionesComponent } from './divisiones/divisiones.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { LoadHappyComponent } from './load-happy/load-happy.component';
import { UploadSurveysComponent } from './upload-surveys/upload-surveys.component';


const gapiClientConfig: NgGapiClientConfig = {
  client_id:
    '244146767518-plte4n06dr2mntr7qegoqcap95v02oa6.apps.googleusercontent.com',
  discoveryDocs: [
    'https://mybusiness.googleapis.com/$discovery/rest?version=v4',
  ],
  scope: ['https://www.googleapis.com/auth/plus.business.manage'].join(' '),
};

moment.locale('es');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DashboardPageComponent,
    NotFoundPageComponent,
    DeveloperPageComponent,
    AdminUsersListComponent,
    AdminUserEditComponent,
    AdminStoresListComponent,
    AdminStoreEditComponent,
    UserEditPageComponent,
    TablePaginationComponent,
    StoreDeviceEditPageComponent,
    AdminDeviceListComponent,
    AdminDeviceEditComponent,
    IsDeveloperDirective,
    MailEditorPageComponent,
    AdminPageComponent,
    MailTemplateListComponent,
    MailTemplateEditorComponent,
    MailTemplateNewComponent,
    AlertListComponent,
    AdminStatsComponent,
    ObjectDumpPipe,
    RoundNumberPipe,
    LoginPageComponent,
    PlaygroundPageComponent,
    UserProfilePageComponent,
    TestPageComponent,
    CsvUploadPageComponent,
    SurveyListComponent,
    SurveyDetailsComponent,
    SurveysPageComponent,
    PromotionsPageComponent,
    PromotionsListComponent,
    PromotionsEditComponent,
    NpspageComponent,
    NpspromotionListComponent,
    NpssurveyListComponent,
    NpssurveysPageComponent,
    NpspromotionEditComponent,
    NpspromotionEditPageComponent,
    NpsMailingTraceComponent,
    AlertPageComponent,
    CustomersPageComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    EverestPageComponent,
    EverestListComponent,
    EverestGenerateComponent,
    EverestResumeComponent,
    DeviceManagerPageComponent,
    EverestProfilesComponent,
    DeviceDashboardResumeComponent,
    HpdashboardResumeComponent,
    NpsdashboardResumeComponent,
    ExportPageComponent,
    EverestDetailPageComponent,
    GmbadminPageComponent,
    MailQueuePageComponent,
    DeviceAssignPageComponent,
    AlertTrackingComponent,
    AlertTrackingGraphComponent,
    FunnelEditorPageComponent,
    HappyPointConfigComponent,
    ActivityUsersComponent,
    UploadHappyComponent,
    GroupsComponent,
    DivisionesComponent,
    AddGroupComponent,
    LoadHappyComponent,
    UploadSurveysComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MomentModule,
    PrettyJsonModule,
    AdminRoutingModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ChartsModule,
    NvD3Module,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
    CountoModule,
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateESParserFormatter },
    CommonApiService,
    UserApiService,
    BaseApiService,
    AdminApiService,
    PrettyJsonModule,
    StoreApiService,
    BqAuthService,
    BqDeveloperAuthService,
    GmbuserService,
    GmbapiService,
    ExcelService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
